import { ChangeDetectionStrategy, Component, computed, inject, input, viewChild, ViewEncapsulation } from '@angular/core';
import { S1Layout } from '@seven1/model';
import { HeaderComponent } from '../../nested';
import { FooterComponent, SidebarComponent } from '../../nested';
import { NgStyle } from '@angular/common';
import { PlatformService } from '@seven1/angular/ssr';

@Component({
    selector: 'app-layout',
    imports: [HeaderComponent, SidebarComponent, FooterComponent, NgStyle],
    templateUrl: './layout.wrapper.html',
    styleUrl: './layout.wrapper.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-layout',
    }
})
export class LayoutWrapper {
    private _platform = inject(PlatformService);
    private _header = viewChild.required(HeaderComponent);
    private _footer = viewChild.required(FooterComponent);

    block = input.required<S1Layout>();
    headerHeight = computed<string>(() => {
        if (this._platform.isBrowser) {
            const headerHeight = this._header().elRef.nativeElement?.getBoundingClientRect()?.height;
            if (headerHeight) {
                return headerHeight + 'px';
            }
        }
        return '0';
    });
    footerHeight = computed<string>(() => {
        if (this._platform.isBrowser) {
            const footerHeight = this._footer().elRef.nativeElement?.getBoundingClientRect()?.height;
            if (footerHeight) {
                return footerHeight + 'px';
            }
        }
        return '0';
    });
}
