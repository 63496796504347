@let blok = block();

<div [@sidebarAnimation]="navigationService.navOpen()? 'open': 'closed'"
     class="fixed h-screen top-0 bottom-0 right-0 z-[60] w-full md:w-[420px] bg-page-background overflow-y-auto lg:bottom-0 translate-x-full pt-5 p-8 shadow-sidebar">
    <button
        class="block ml-auto pb-2"
        (click)="navigationService.toggleNav()"
        [attr.aria-label]="navigationService.navOpen() ? 'Close menu' : 'Open menu'">
        <img
            ngSrc="/icons/close.svg"
            width="64"
            height="64"
            [alt]="navigationService.navOpen() ? 'Close menu' : 'Open menu'"
        />
    </button>
    @for (child of blok.nav_children; track child._uid) {
        <div>
            <s1-cms-component [blok]="child">
            </s1-cms-component>
        </div>
    }
</div>
