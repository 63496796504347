import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { IAdvantagesCardBlock } from './advantagesCard.model';
import { TailwindService } from '../../../../service';

@Component({
    selector: 'app-advantages-card',
    imports: [NgClass],
    templateUrl: './advantagesCard.component.html',
    styleUrl: './advantagesCard.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'flex flex-col md:flex-row align-center md:items-center gap-3 mx-auto p-12 rounded-lg shadow-card bg-white',
        '[class]': 'hostClasses()',
    }
})
export class AdvantagesCardComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<IAdvantagesCardBlock>();

    hostClasses = computed<string[]>(() => {
        const block = this.block();
        return [
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
        ];
    });

    priceClasses = computed<string[]>(() => {
        return [...this._tailwind.getTypographyClasses({color: this.block().priceColor})];
    });
}
