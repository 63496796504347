import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';

import { TailwindService } from '../../../../../service';
import { IGridTwoColumnBlock } from './grid-two-column.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { NgClass } from '@angular/common';

@Component({
    selector: 'div[app-grid-two-column]',
    imports: [CmsComponent, NgClass],
    templateUrl: './grid-two-column.component.html',
    styleUrl: './grid-two-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'grid grid-cols-1 sm:grid-cols-2',
        '[class]': 'classes()',
    }
})
export class GridTwoColumnComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IGridTwoColumnBlock>();
    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getGapClasses(block),
            ...this._tailwind.getSpacingClasses(block)
        ];
    });
}
