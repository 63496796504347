import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';
import { ILinkCardBlock } from './linkCard.model';
import { TailwindService } from '../../../../service';
import { ImageComponent } from '../../media';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { RouterLink } from '@angular/router';
import { CmsComponent } from '@seven1/angular-storyblok/components';

@Component({
    selector: 'app-link-card',
    imports: [ImageComponent, CachedUrlResolverPipe, DesanitizeSlashPipe, NgTemplateOutlet, RouterLink, CmsComponent],
    templateUrl: './linkCard.component.html',
    styleUrl: './linkCard.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'flex flex-col gap-1 rounded-lg shadow-card bg-white w-64 min-[350px]:w-72 sm:w-80 min-h-40 h-full ',
        '[class]': 'hostClasses()',
    }
})
export class LinkCardComponent {
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);
    block = input.required<ILinkCardBlock>();

    hostClasses = computed<string[]>(() => {
        return [...this._tailwind.getDisplayClasses(this.block())]
    });
    sanitizedLink = computed(() => {
        const link = this.block().link;
        if (link !== undefined) {
            return SbLinkHelper.sanitizeLink(link, this._sanitizer);
        }
        return ''
    });

}
