@let b = block();

<div class="flex flex-col gap-3 w-48">
    @if (b.headingText) {
        <h3 class="text-xl break-words">{{ b.headingText }}</h3>
    }
    @if (b.price) {
        <b class="text-4xl font-extrabold" [ngClass]="priceClasses()">{{ b.price }} €</b>
    }
</div>
<p class="w-full break-words text-lg font-normal">{{ b.descriptionText }}</p>
