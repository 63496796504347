@let blok = block();
@let isMobile = !!(breakpoints.isMobile$ | async)?.matches;
@let isTablet = !!(breakpoints.isTablet$ | async)?.matches;
@let isDesktop = !!(breakpoints.isDesktop$ | async)?.matches;

<section class="app-decorated-section--wrapper"
         [ngClass]="classes()">
  @if (isMobile || isTablet || isDesktop) {
    <!-- todo: problem - image only loads when js is exewcuted -->
    @if (isTablet && blok.backgroundImageTablet?.filename) {
      <img class="app-decorated-section--background"
           [ngSrc]="blok.backgroundImageTablet!.filename"
           fill
           [sizes]="sizes()"
           [alt]="blok.backgroundImageTablet!.alt"
           [title]="blok.backgroundImageTablet!.title"
           [loaderParams]="loadParamsTablet()"
           [priority]="blok.priority" />
    } @else if (isDesktop && blok.backgroundImageDesktop?.filename) {
      <img class="app-decorated-section--background"
           [ngSrc]="blok.backgroundImageDesktop!.filename"
           fill
           [sizes]="sizes()"
           [alt]="blok.backgroundImageDesktop!.alt"
           [title]="blok.backgroundImageDesktop!.title"
           [loaderParams]="loadParamsDesktop()"
           [priority]="blok.priority" />
    } @else if (blok.backgroundImage) {
      <img class="app-decorated-section--background"
           [ngSrc]="blok.backgroundImage.filename"
           fill
           [sizes]="sizes()"
           [alt]="blok.backgroundImage.alt"
           [title]="blok.backgroundImage.title"
           [loaderParams]="loadParams()"
           [priority]="blok.priority" />
    }
  }

  @if (blok.children?.length) {
    <div class="app-decorated-section--children app-limited-content-width">
      @for (child of blok.children; track child._uid) {
        <s1-cms-component [blok]="child" />
      }
    </div>
  }
</section>

@if (blok.childrenBottom?.length) {
  <div
    class="relative app-decorated-section--children-bottom app-limited-content-width z-10">
    @for (child of blok.childrenBottom; track child._uid) {
      <s1-cms-component [blok]="child" />
    }
  </div>
}
