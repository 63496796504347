@let blok = block();
@let inf = loadParams();
@let link = blok?.link;

@if (link) {

    @switch (link?.linktype) {
        @case ('url') {
            <a [href]="sanitizedLink() | desanitizeSlash"
               [target]="link?.target"
               [rel]="link?.target === '_blank' ? 'noopener noreferrer' : undefined">
                <ng-container [ngTemplateOutlet]="image"/>
            </a>
        }
        @case ('story') {
            <a [routerLink]="link?.cached_url | cachedUrlResolver"
               [fragment]="link?.anchor ? link?.anchor : undefined"
               [target]="link?.target"
               (click)="(link?.anchor) && scrollTo(link?.anchor)"
            >
                <ng-container [ngTemplateOutlet]="image"/>
            </a>
        }
        @case ('asset') {
            <a [href]="sanitizedLink() | desanitizeSlash"
               [target]="link?.target">
                <ng-container [ngTemplateOutlet]="image"/>
            </a>
        }
        @case ('email') {
            <a [href]="'mailto:' + sanitizedLink() | desanitizeSlash">
                <ng-container [ngTemplateOutlet]="image"/>
            </a>
        }
    }
} @else {
    <ng-container [ngTemplateOutlet]="image"/>
}


<ng-template #image>
    @if(inf?.width && inf?.height){
        <img class="block"
             [ngClass]="classes()"
             [ngSrc]="blok.asset.filename"
             [sizes]="sizes()"
             [width]="inf?.width"
             [height]="inf?.height"
             [alt]="blok.asset.alt"
             [title]="blok.asset.title"
             [loaderParams]="inf || {}"
             [priority]="blok.priority"
        >
    }
</ng-template>
