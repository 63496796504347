@let b = block();
@switch (b.level) {
    @case ("1") {
        <p [ngClass]="accentTextClasses()">
            {{ b.accentText }}
        </p>
        <h1 [ngClass]="headingClasses()">
            <ng-container [ngTemplateOutlet]="headingContent"/>
        </h1>
    }
    @case ("2") {
        <p [ngClass]="accentTextClasses()">
            {{ b.accentText }}
        </p>
        <h2 [ngClass]="headingClasses()">
            <ng-container [ngTemplateOutlet]="headingContent"/>
        </h2>
    }
    @case ("3") {
        <p [ngClass]="accentTextClasses()">
            {{ b.accentText }}
        </p>
        <h3 [ngClass]="headingClasses()">
            <ng-container [ngTemplateOutlet]="headingContent"/>
        </h3>
    }
    @case ("4") {
        <p [ngClass]="accentTextClasses()">
            {{ b.accentText }}
        </p>
        <h4 [ngClass]="headingClasses()">
            <ng-container [ngTemplateOutlet]="headingContent"/>
        </h4>
    }
    @case ("5") {
        <p [ngClass]="accentTextClasses()">
            {{ b.accentText }}
        </p>
        <h5 [ngClass]="headingClasses()">
            <ng-container [ngTemplateOutlet]="headingContent"/>
        </h5>
    }
    @default {
        <!-- "6" -->
        <p [ngClass]="accentTextClasses()">
            {{ b.accentText }}
        </p>
        <h6 [ngClass]="headingClasses()">
            <ng-container [ngTemplateOutlet]="headingContent"/>
        </h6>
    }
}

<ng-template #headingContent>
    @if (b.textStart) {
        <span>{{ b.textStart }}</span>
    }
    @if (b.textCenter) {
        @if (b.textEmphasized) {
            <b [ngClass]="this.block().textEmphasized? 'uppercase font-extrabold':''"> {{ b.textCenter }} </b>
        } @else {
            {{ b.textCenter }}
        }
    }
    @if (b.textEnd) {
        <span>{{ b.textEnd }}</span>
    }
</ng-template>
