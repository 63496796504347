import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { TitleStrategy } from '@angular/router';
import { PageTitleStrategy } from './seo-title.strategy';

export function provideSeo(): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: TitleStrategy,
            useClass: PageTitleStrategy
        }
    ]);
}
