import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ITariffTableBlock } from './tariff-table.model';
import { TariffTableColumnComponent } from './column';
import { TariffTableRowGroupComponent } from './row-group';
import { TariffTableFooterRowComponent } from './footer';
import { TailwindService } from '../../../../../service';

@Component({
    selector: 'app-tariff-table',
    imports: [TariffTableColumnComponent, TariffTableRowGroupComponent, TariffTableFooterRowComponent],
    templateUrl: './tariff-table.component.html',
    styleUrl: './tariff-table.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[style]': `{
            "--tariff-table-columns-count": block().columns?.length || 0
        }`,
        '[class]': `classes()`,
        '[class.app-limited-content-width]': `!!block().limitedWidth`,
        '[attr.id]': '!block().componentId ? null : block().componentId'
    }
})
export class TariffTableComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<ITariffTableBlock>();

    classes = computed(() => {
        const block = this.block();
        return ['block',
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getDisplayClasses(block)];
    });
}
