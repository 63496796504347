import { CanDeactivateFn } from '@angular/router';
import { inject } from '@angular/core';
import { GtmService } from '../gtm.service';

export const routeChangeDeactivateGuard: CanDeactivateFn<unknown> = (
  component,
  currentRoute,
  currentState,
) => {
  const gtmService = inject(GtmService);
  gtmService.routingDeactivation('', currentState.url);
  return true;
};
