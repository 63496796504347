import {
    ChangeDetectionStrategy,
    Component, computed,
    input,
    ViewEncapsulation
} from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { IListElementBlock } from './listElement.model';

@Component({
    selector: 'li[app-list-element]',
    imports: [NgOptimizedImage],
    templateUrl: './listElement.component.html',
    styleUrl: './listElement.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()'
    }
})
export class ListElementComponent {
    block = input.required<IListElementBlock>();

    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            'flex',
            'items-center',
            block.underline ? 'with-divider' : ''
        ];
    })
}
