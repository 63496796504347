@let blok = block();
<div class="app-grid-column--1 flex flex-col">
    @for (child of blok.column_one; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>
<div class="app-grid-column--2 flex flex-col">
    @for (child of blok.column_two; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>
<div class="app-grid-column--3 flex flex-col">
    @for (child of blok.column_three; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>
<div class="app-grid-column--4 flex flex-col">
    @for (child of blok.column_four; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>
<div class="app-grid-column--5 flex flex-col">
    @for (child of blok.column_five; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>

