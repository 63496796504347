import { DestroyRef, inject, Injectable } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Injectable({
  providedIn: 'root'
})
export class BreakpointsService {
    private _breakpointObserver = inject(BreakpointObserver);
    private _destroy = inject(DestroyRef);

    minSm$ = this._breakpointObserver.observe('(min-width: 640px)').pipe(takeUntilDestroyed(this._destroy));
    maxSm$ = this._breakpointObserver.observe('(max-width: 640px)').pipe(takeUntilDestroyed(this._destroy));

    minMd$ = this._breakpointObserver.observe('(min-width: 768px)').pipe(takeUntilDestroyed(this._destroy));
    maxMd$ = this._breakpointObserver.observe('(max-width: 768px)').pipe(takeUntilDestroyed(this._destroy));

    minLg$ = this._breakpointObserver.observe('(min-width: 1024px)').pipe(takeUntilDestroyed(this._destroy));
    maxLg$ = this._breakpointObserver.observe('(max-width: 1024px)').pipe(takeUntilDestroyed(this._destroy));

    minXl$ = this._breakpointObserver.observe('(min-width: 1280px)').pipe(takeUntilDestroyed(this._destroy));
    maxXl$ = this._breakpointObserver.observe('(max-width: 1280px)').pipe(takeUntilDestroyed(this._destroy));

    min2xl$ = this._breakpointObserver.observe('(min-width: 1440px)').pipe(takeUntilDestroyed(this._destroy));
    max2xl$ = this._breakpointObserver.observe('(max-width: 1440px)').pipe(takeUntilDestroyed(this._destroy));

    isMobile$ = this._breakpointObserver.observe('(max-width: 768px)').pipe(takeUntilDestroyed(this._destroy));
    isTablet$ = this._breakpointObserver.observe('(min-width: 768px) and (max-width: 1024px)').pipe(takeUntilDestroyed(this._destroy));
    isDesktop$ = this._breakpointObserver.observe('(min-width: 1024px)').pipe(takeUntilDestroyed(this._destroy));
}
