@let blok = block();

<video [poster]="blok.poster?.filename"
       [controls]="blok.controls"
       [autoplay]="blok.autoplay"
       [muted]="blok.autoplay || blok.muted"
       [loop]="blok.loop"
       class="aspect-video w-full object-cover">
    @if (blok.video?.filename) {
        <source [src]="sanitizedVideoUrl()">
    }
</video>
