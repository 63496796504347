import { ChangeDetectionStrategy, Component, computed, ElementRef, inject, input, ViewEncapsulation } from '@angular/core';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { IFooterBlock } from './footer.model';
import { ImageComponent } from '../../media';
import { TailwindService } from '../../../../service';

@Component({
    selector: 'app-footer',
    imports: [CmsComponent, ImageComponent],
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    }
})
export class FooterComponent {
    elRef = inject(ElementRef<HTMLElement>);
    block = input.required<IFooterBlock>();
    private _tailwind = inject(TailwindService);

    classes = computed(() => {
        const block = this.block();
        return [
            'block',
            ...this._tailwind.getBackgroundColorClasses({backgroundColor: block.footerBackgroundColor})
        ];
    });
}
