@let blok = block();
@let col = column();

@if (col) {
    <div class="app-tariff-table--row-column-title lg:hidden">
        {{col.title}}
    </div>
}

@for (content of blok.content; track content._uid) {
    @switch (content?.component) {
        @case ('text') {
            <span>{{$any(content)?.text}}</span>
        }
        @case ('icon') {
            <app-icon [block]="$any(content)" />
        }
    }
}
