@let blok = block();
@let isOpen = open();

<button (click)="toggle()"
        class="app-faq-item--toggle"
        [class.is-open]="isOpen">
    <h4 class="app-faq-item--toggle-title"
        [attr.aria-describedby]="id">
        {{ blok.question }}
    </h4>
    <img src="/icons/arrow.svg"
         class="app-faq-item--toggle-chevron"
         alt="chevron to close and open faq item"
         height="24"
         width="24"
         loading="lazy" />
</button>
@if (blok.answers?.length) {
    <div [id]="id"
         [attr.aria-hidden]="!isOpen"
         class="app-faq-item--content"
         [class.hidden]="!isOpen">
        @for (answer of blok.answers; track answer._uid) {
            <p class="app-faq-item--paragraph">
                {{answer.text}}
            </p>
        }
    </div>
}
