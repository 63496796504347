@let b = block();

@switch(b.level) {
    @case ("1") {
        <h1 [ngClass]="classes()">{{b.text}}</h1>
    }
    @case ("2") {
        <h2 [ngClass]="classes()">{{b.text}}</h2>
    }
    @case ("3") {
        <h3 [ngClass]="classes()">{{b.text}}</h3>
    }
    @case ("4") {
        <h4 [ngClass]="classes()">{{b.text}}</h4>
    }
    @case ("5") {
        <h5 [ngClass]="classes()">{{b.text}}</h5>
    }
    @default { <!-- "6" -->
        <h6 [ngClass]="classes()">{{b.text}}</h6>
    }
}
