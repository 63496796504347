import { Injectable } from '@angular/core';
import { ISbImageUrlInfo } from '@seven1/model';

export function sbExtractUrlInfo(url: string | undefined): ISbImageUrlInfo | null {
    const split = url?.split('/');
    if (split) {
        const dimensions = split[5].split('x');
        return {
            spaceId: split[4],
            width: parseInt(dimensions[0]),
            height: parseInt(dimensions[1]),
        }
    }
    return null;
}

@Injectable({
  providedIn: 'root'
})
export class ImageService {

    /**
     * convert a asset url of an image to it's width and height values
     * @param url - a storyblok image url, e.g. `https://a.storyblok.com/f/308668/4000x2671/7e985d1ce2/tob_and_simon.webp`
     * @returns an `ISbImageUrlInfo` with `width` and `height` and `spaceId` attributes
     * */
    extractUrlInfo(url: string): ISbImageUrlInfo | null {
        return sbExtractUrlInfo(url);
    }
}
