import { Route } from '@angular/router';
import { layoutResolver, storyResolver } from './cms/resolver';
import { routeChangeActivateGuard, routeChangeDeactivateGuard } from '@seven1/angular/gtm';
import { navigationDeactivateGuard } from './routing/route-change-activate.guard';

export const STORY_ROUTE_PARAM_KEY = 'story-slug';

export const appRoutes: Route[] = [
    {
        path: '**',
        loadComponent: () => import('./cms/page').then(cmp => cmp.CmsPage),
        resolve: {
            story: storyResolver,
            layout: layoutResolver,
        },
        canActivate: [routeChangeActivateGuard],
        canDeactivate: [
            routeChangeDeactivateGuard,
            navigationDeactivateGuard,
        ],
    },
];
