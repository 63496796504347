import { inject, Injectable, isDevMode } from '@angular/core';
import { GTM_CONFIG, GtmWindow } from './gtm.provider';
import { PlatformService, WINDOW } from '@seven1/angular/ssr';
import { UrlSegment } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GtmService {
    private _platform = inject(PlatformService);
    private _window = inject(WINDOW) as GtmWindow;
    private _config = inject(GTM_CONFIG);

    init() {
        // do something
    }

    /**
     * logs an event to google tag manager `window.dataLayer` (if inside browser)
     * @param event - name of the event
     * @param category - category name of the event
     * @param action - action name of the event
     * @param label - label of the event
     * @param data - additional data for the event
     * */
    private _logEvent(event: string, category?: string, action?: string, label?: string, data?: {[key: string]: unknown}): void {
        if (this._platform.isBrowser) {
            this._window.dataLayer?.push({event, category, action, label, ...data});
        }
    }

    routingActivation(event: string, url: UrlSegment[]): void {
        this._logEvent(event, 'route_change', 'activate_route', undefined, {url});
    }

    routingDeactivation(event: string, url: string): void {
        this._logEvent(event, 'route_change', 'deactivate_route', undefined, {url});
    }
}
