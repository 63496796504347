import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITariffTableColumnBlock } from '../../column';
import { ITariffTableRowColumnBlock } from './tariff-table-row-column.model';
import { IconComponent } from '../../../../../media';

@Component({
    selector: 'div[app-tariff-table-row-column]',
    imports: [IconComponent],
    templateUrl: './tariff-table-row-column.component.html',
    styleUrl: './tariff-table-row-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-table--row-column flex flex-col items-center justify-center text-center text-md text-accent-dark font-bold',
    }
})
export class TariffTableRowColumnComponent {
    block = input.required<ITariffTableRowColumnBlock>();
    column = input<ITariffTableColumnBlock>();
}
