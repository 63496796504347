import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';

import { TailwindService } from '../../../../service';
import { ISectionBlock } from './section.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';

@Component({
    selector: 'section[app-section]',
    imports: [CmsComponent],
    templateUrl: './section.component.html',
    styleUrl: './section.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-section',
        '[class]': 'classes()',
        '[attr.id]': 'block().componentId',
    }
})
export class SectionComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<ISectionBlock>();

    classes = computed(() => {
        const block = this.block();
        const classes = [
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getBackgroundColorClasses(block),
            ...this._tailwind.getDisplayClasses(block)
        ];
        if (block.limitedWidth) {
            classes.push('app-section--limited-width')
        }
        return classes;
    });
}
