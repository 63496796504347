import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITariffTableColumnBlock } from '../../column';
import { ITariffTableFooterColumnBlock } from './tariff-table-footer-column.model';
import { ButtonComponent } from '../../../../../cta';

@Component({
    selector: 'div[app-tariff-table-footer-column]',
    imports: [ButtonComponent],
    templateUrl: './tariff-table-footer-column.component.html',
    styleUrl: './tariff-table-footer-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-table--footer-column'
    }
})
export class TariffTableFooterColumnComponent {
    block = input.required<ITariffTableFooterColumnBlock>();
    columns = input<ITariffTableColumnBlock[]>();
}
