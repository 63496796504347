import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { ITariffOptionCardsBlock } from './tariff-option-cards.model';
import { TariffOptionCardComponent } from './card';
import { TailwindService } from '../../../../../service';

@Component({
    selector: 'app-tariff-option-cards',
    imports: [TariffOptionCardComponent],
    templateUrl: './tariff-option-cards.component.html',
    styleUrl: './tariff-option-cards.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
        class: 'flex flex-wrap flex-col gap-8 items-center lg:flex-row lg:items-end lg:justify-center',
        '[class.app-limited-content-width]': '!!block().limitedWidth',
        '[attr.id]': 'block().componentId'
    }
})
export class TariffOptionCardsComponent {
    private _tailwind = inject(TailwindService);
    block = input.required<ITariffOptionCardsBlock>();

    classes = computed<string[]>(() => {
        return [
            ...this._tailwind.getDisplayClasses(this.block(),"flex"),
            ...this._tailwind.getSpacingClasses(this.block()),
        ]
    });
}
