import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITariffTableRowGroupBlock } from './tariff-table-row-group.model';
import { TariffTableRowComponent } from '../row';
import { ITariffTableColumnBlock } from '../column';

@Component({
    selector: 'section[app-tariff-table-row-group]',
    imports: [TariffTableRowComponent],
    templateUrl: './tariff-table-row-group.component.html',
    styleUrl: './tariff-table-row-group.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-table--row-group',
    }
})
export class TariffTableRowGroupComponent {
    block = input.required<ITariffTableRowGroupBlock>();
    columns = input<ITariffTableColumnBlock[]>();
}
