import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { IParagraphBlock } from './paragraph.model';
import { TailwindService } from '../../../../service';

@Component({
    selector: 'p[app-paragraph]',
    imports: [],
    templateUrl: './paragraph.component.html',
    styleUrl: './paragraph.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class]': 'classes()',
    }
})
export class ParagraphComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IParagraphBlock>();
    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            'break-words',
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getTypographyClasses(block),
        ];
    });
}
