import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { IFooterRecruitBlock } from './footer-recruit.model';

@Component({
    selector: 'app-footer-recruit',
    imports: [CmsComponent],
    templateUrl: './footer-recruit.component.html',
    styleUrl: './footer-recruit.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'block bg-grey-dark text-primary px-5 py-8',
    }
})
export class FooterRecruitComponent {
    block = input.required<IFooterRecruitBlock>();
}
