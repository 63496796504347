import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';

import { TailwindService } from '../../../../../service';
import { IGridFourColumnBlock } from './grid-four-column.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';

@Component({
    selector: 'div[app-grid-four-column]',
    imports: [CmsComponent],
    templateUrl: './grid-four-column.component.html',
    styleUrl: './grid-four-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4',
        '[class]': 'classes()',
    }
})
export class GridFourColumnComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IGridFourColumnBlock>();
    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getGapClasses(block),
            ...this._tailwind.getSpacingClasses(block)
        ];
    });
}
