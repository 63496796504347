import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, provideAppInitializer } from '@angular/core';
import { gtmInitializerFn } from './gtm.initializer';

export interface GtmConfig {
    url: string,
    id: string,
}

export interface GtmEcommerceItem {
    item_id?: string;
    item_name?: string;
    item_brand?: string;
    item_category?: string;
    item_variant?: string;
    currency?: string;
    price?: string;
}

export interface GtmEvent {
    event: string;
    gtm?: { uniqueEventId?: number, start: number };
    ecommerce?: {
        items?: GtmEcommerceItem[]
    };
    category?: string;
    action?: string;
    label?: string;
    [key: string]: unknown;
}

export type GtmWindow = Window & typeof globalThis & {
    dataLayer?: GtmEvent[]
}

export const GTM_CONFIG = new InjectionToken<GtmConfig>('GTM_CONFIG');

export function provideGtm(config: GtmConfig): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: GTM_CONFIG, useValue: config},
        provideAppInitializer(gtmInitializerFn),
    ]);
}
