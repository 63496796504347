import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { IHeadlineBlock } from './headline.model';
import { TailwindService } from '../../../../service';

@Component({
    selector: 'app-headline',
    imports: [NgClass],
    templateUrl: './headline.component.html',
    styleUrl: './headline.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlineComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IHeadlineBlock>();
    classes = computed<string[]>(() => {
        const block = this.block();
        return [
            'break-words',
            ...this._tailwind.getDisplayClasses(block),
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block),
            ...this._tailwind.getTypographyClasses(block),
        ];
    });
}
