import { inject, Injectable, Renderer2, RendererFactory2, SecurityContext } from '@angular/core';
import { DomSanitizer, Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { IPageSeo, ISbAsset, PageOpenGraphType, PageRobots } from '@seven1/model';
import { DOCUMENT } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SeoService {
    private _meta = inject(Meta);
    private _title = inject(Title);
    private _document = inject(DOCUMENT);
    private _renderer: Renderer2;
    private _sanitizer = inject(DomSanitizer);

    constructor(factory: RendererFactory2) {
        this._renderer = factory.createRenderer(null, null);
    }

    private _addTitleDef(title?: string): void {
        if (title?.length) {
            this._title.setTitle(title);
        }
    }

    private _addDescriptionDef(defs: MetaDefinition[], description?: string): void {
        if (description?.length) {
            defs.push({
                name: 'description',
                content: description,
            });
        }
    }

    private _addRobotsDef(defs: MetaDefinition[], robots?: PageRobots): void {
        if (robots?.length) {
            defs.push({
                name: 'robots',
                content: robots,
            });
        }
    }

    private _addOgTitleDef(defs: MetaDefinition[], ogTitle?: string): void {
        if (ogTitle?.length) {
            defs.push({
                name: 'og:title',
                content: ogTitle,
            });
        }
    }

    private _addOgDescriptionDef(defs: MetaDefinition[], ogDescription?: string): void {
        if (ogDescription?.length) {
            defs.push({
                name: 'og:description',
                content: ogDescription,
            });
        }
    }

    private _addOgImageDef(defs: MetaDefinition[], ogImage?: ISbAsset): void {
        if (ogImage?.filename?.length) {
            defs.push({
                name: 'og:image',
                content: ogImage.filename,
            });
        }
    }

    private _addOgUrlDef(defs: MetaDefinition[], url?: string): void {
        if (url?.length) {
            defs.push({
                name: 'og:url',
                content: url,
            });
        }
    }

    private _addOgTypeDef(defs: MetaDefinition[], type?: PageOpenGraphType): void {
        if (type?.length) {
            defs.push({
                name: 'og:type',
                content: type,
            });
        }
    }

    private _addCanonicalUrl(url?: string): void {
        if (url?.length) {
            const el: HTMLLinkElement = this._renderer.createElement('link');
            this._renderer.setAttribute(el, 'rel', 'canonical');
            this._renderer.setAttribute(el, 'href', this._sanitizer.sanitize(SecurityContext.URL, url) || '');
            this._renderer.appendChild(this._document.head, el);
        }
    }

    addPageMeta(meta: IPageSeo): HTMLMetaElement[] {
        const defs: MetaDefinition[] = [];
        this._addTitleDef(meta.meta_title);
        this._addDescriptionDef(defs, meta.meta_description);
        this._addRobotsDef(defs, meta.meta_robots);
        this._addOgTitleDef(defs, meta.meta_ogTitle);
        this._addOgDescriptionDef(defs, meta.meta_ogDescription);
        this._addOgImageDef(defs, meta.meta_ogImage);
        this._addOgUrlDef(defs, meta.meta_ogUrl);
        this._addOgTypeDef(defs, meta.meta_ogType);
        this._addCanonicalUrl(meta.meta_canonicalUrl);
        return this._meta.addTags(defs);
    }
}
