import { CatConfig, DogConfig, HorseConfig } from '../app/cms/components/nested';

export type SbIFrameEnv = {
    dog: DogConfig;
    cat: CatConfig;
    horse: HorseConfig;
    general: string
};

export const STORYBLOK_IFRAMES: SbIFrameEnv = {
    dog: {
        // op insurance
        damage: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=op&tarif=unfall&sb=1',
        exclusive_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=op&tarif=exklusiv&sb=1',
        exclusive_plus_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=op&tarif=exklusiv%20plus&sb=1',
        // liability insurance
        basis: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=Hund&tarif=basis&sb=1',
        top: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=Hund&tarif=top&sb=1',
        premium: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=Hund&tarif=premium&sb=1',
        // health insurance
        op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=op-exklusiv',
        comfort: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=komfort',
        exclusive: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=exklusiv',
        exclusive_plus: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Hund&versicherung=alle&sb=1&vuz=1&tarif=exklusiv plus',
    },
    cat: {
        // op insurance
        exclusive_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=op&tarif=exklusiv&sb=1',
        exclusive_plus_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=op&tarif=exklusiv%20plus&sb=1',
        // health insurance
        op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=op-exklusiv',
        comfort: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=komfort',
        exclusive: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=exklusiv',
        exclusive_plus: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Katze&versicherung=alle&sb=1&vuz=1&tarif=exklusiv plus',
    },
    horse: {
        // op insurance
        top_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Pferd&versicherung=op&tarif=top&sb=1',
        premium_op: 'https://versichern.petprotect.de/online-versichern/#/petprotect-tier/Beitrag?tierart=Pferd&versicherung=op&tarif=premium&sb=1',
        // liability insurance
        basis: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=pferd&tarif=basis',
        top: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=pferd&tarif=top',
        premium: 'https://versichern.petprotect.de/online-versichern/#/petprotect-thv/Beitrag?tierart=pferd&tarif=premium',
    },
    general: 'https://beratung.petprotect.de/infoanforderung/#/pp/Neu'
};
