import { ChangeDetectionStrategy, Component, input, signal, ViewEncapsulation } from '@angular/core';
import { IFaqItemBlock } from './faq-item.model';

@Component({
    selector: 'li[app-faq-item]',
    imports: [],
    templateUrl: './faq-item.component.html',
    styleUrl: './faq-item.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-faq-item',
    }
})
export class FaqItemComponent {
    static nextId = 0;
    id = `app-faq-item-${++FaqItemComponent.nextId}`;
    block = input.required<IFaqItemBlock>();
    open = signal(false);

    toggle() {
        this.open.set(!this.open());
    }
}
