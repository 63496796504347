import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';

import { TailwindService } from '../../../../service';
import { IGroupBlock } from './group.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';

@Component({
    selector: 'div[app-group]',
    imports: [CmsComponent],
    templateUrl: './group.component.html',
    styleUrl: './group.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-group',
        '[class]': 'classes()',
    }
})
export class GroupComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IGroupBlock>();

    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getSpacingClasses(block),
            ...this._tailwind.getSizeClasses(block)
        ];
    });
}
