import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITariffTableColumnBlock } from '../column';
import { ITariffTableFooterRowBlock } from './tariff-table-footer-row.model';
import { TariffTableFooterColumnComponent } from './column';
import { ButtonComponent } from '../../../../cta';

@Component({
    selector: 'footer[app-tariff-table-footer-row]',
    imports: [TariffTableFooterColumnComponent, ButtonComponent],
    templateUrl: './tariff-table-footer-row.component.html',
    styleUrl: './tariff-table-footer-row.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-table--footer',
    }
})
export class TariffTableFooterRowComponent {
    block = input.required<ITariffTableFooterRowBlock>();
    columns = input<ITariffTableColumnBlock[]>();
}
