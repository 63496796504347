import {
    ChangeDetectionStrategy,
    Component,
    computed, ElementRef,
    HostListener,
    inject,
    input,
    signal,
    ViewEncapsulation
} from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { IHeaderBlock } from './header.model';
import { ImageComponent } from '../../media';
import { NavigationService } from '../../../../service';
import { CachedUrlResolverPipe, SbLinkHelper } from '@seven1/angular-storyblok/link';
import { DomSanitizer } from '@angular/platform-browser';
import { DesanitizeSlashPipe } from '@seven1/angular/utils';
import { RouterLink } from '@angular/router';
import { PhoneNumberComponent } from '../../cta';
import { PlatformService } from '@seven1/angular/ssr';

@Component({
    selector: 'header[app-header]',
    imports: [
        NgOptimizedImage,
        ImageComponent,
        NgTemplateOutlet,
        CachedUrlResolverPipe,
        DesanitizeSlashPipe,
        RouterLink,
        PhoneNumberComponent,
    ],
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-header',
        '[class.bg-white]': 'isScrolled()',
    }
})
export class HeaderComponent {
    block = input.required<IHeaderBlock>();
    navigationService = inject(NavigationService);
    elRef = inject(ElementRef<HTMLDivElement>);
    _sanitizer = inject(DomSanitizer);
    platformService = inject(PlatformService);

    sanitizedLink = computed(() => {
        return SbLinkHelper.sanitizeLink(this.block().headerImageLink, this._sanitizer);
    });

    isScrolled = signal(false);

    @HostListener('window:scroll', [])
    onScroll(): void {
        if (this.platformService.isBrowser) {
            const scrollPosition = window.scrollY || document.documentElement.scrollTop;
            this.isScrolled.set(scrollPosition > 100);
        }
    }
}
