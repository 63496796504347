import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { JsonLdSchemaOption } from '@seven1/model';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class JsonldService {
    private _rendererFactory = inject(RendererFactory2);
    private _renderer: Renderer2;
    private _document = inject(DOCUMENT);

    constructor() {
        this._renderer = this._rendererFactory.createRenderer(null, null);
    }

    createSchema(schema: JsonLdSchemaOption, id: string): HTMLScriptElement {
        const el: HTMLScriptElement = this._renderer.createElement('script');
        el.setAttribute('type', 'application/ld+json');
        el.setAttribute('id', `${id}--jsonld`);
        el.innerHTML = JSON.stringify(schema);
        this._renderer.appendChild(this._document.head, el);
        return el;
    }
}
