import {ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation} from '@angular/core';
import { NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import {ImageService} from '@seven1/angular-storyblok/image';
import {ISbImageLoadParams} from '@seven1/model';
import {TailwindService} from '../../../../service';
import {IIconBlock} from './icon.model';
import {CachedUrlResolverPipe, SbLinkHelper} from "@seven1/angular-storyblok/link";
import {DesanitizeSlashPipe} from "@seven1/angular/utils";
import {DomSanitizer} from "@angular/platform-browser";
import {RouterLink} from "@angular/router";

@Component({
    selector: 'app-icon',
    imports: [NgOptimizedImage, CachedUrlResolverPipe, DesanitizeSlashPipe, RouterLink, NgTemplateOutlet],
    templateUrl: './icon.component.html',
    styleUrl: './icon.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'block',
        '[class]': 'iconSize()',
    }
})
export class IconComponent {
    private _images = inject(ImageService);
    private _tailwind = inject(TailwindService);
    private _sanitizer = inject(DomSanitizer);

    block = input.required<IIconBlock>();
    sizes = input<string>('100vw');

    loadParams = computed<ISbImageLoadParams>(() => {
        const block = this.block();
        const params = (this._images.extractUrlInfo(block.asset.filename) || {}) as ISbImageLoadParams;
        params.quality = block.quality;
        return params;
    });

    iconSize = computed(() => {
        const block = this.block();
        const tailwindClasses = this._tailwind.getSpacingClasses(block);
        if (block.iconSize === 'large') {
            tailwindClasses.push('h-32', 'w-32');
            return tailwindClasses;
        } else if (block.iconSize === 'medium') {
            tailwindClasses.push('h-16,', 'w-16');
            return tailwindClasses;
        }
        tailwindClasses.push('h-10', 'w-10');
        return tailwindClasses;
    });

    sanitizedLink = computed(() => {
        const link = this.block()?.link;
        if (link) {
            return SbLinkHelper.sanitizeLink(link, this._sanitizer);
        }
        return null;
    });
}
