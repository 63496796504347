import { ChangeDetectionStrategy, Component, computed, inject, input, ViewEncapsulation } from '@angular/core';

import { TailwindService } from '../../../../../service';
import { IGridFiveColumnBlock } from './grid-five-column.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';

@Component({
    selector: 'div[app-grid-five-column]',
    imports: [CmsComponent],
    templateUrl: './grid-five-column.component.html',
    styleUrl: './grid-five-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-5',
        '[class]': 'classes()',
    }
})
export class GridFiveColumnComponent {
    private _tailwind = inject(TailwindService);

    block = input.required<IGridFiveColumnBlock>();
    classes = computed(() => {
        const block = this.block();
        return [
            ...this._tailwind.getGapClasses(block),
            ...this._tailwind.getSpacingClasses(block)
        ];
    });
}
