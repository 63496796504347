export type JsonLdSchemaType = 'FAQPage' | string;

export abstract class JsonLdSchema {
  '@context' = 'https://schema.org';
  '@type': string;

  constructor(
    type: JsonLdSchemaType,
  ) {
    this['@type'] = type;
  }
}

export interface FaqJsonLdSchemaMainEntity {
  "@type": "Question",
  "name": string,
  "acceptedAnswer": {
    "@type": "Answer",
    "text": string
  }
}

export class FaqJsonLdSchema extends JsonLdSchema {

  constructor(
    public mainEntity: FaqJsonLdSchemaMainEntity[] = [],
  ) {
    super('FAQPage');
  }

  addQuestion(question: string, answer: string) {
    this.mainEntity = [...this.mainEntity, {
      "@type": "Question",
      "name": question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": answer
      }
    }]
  }
}

export type JsonLdSchemaOption = FaqJsonLdSchema;
