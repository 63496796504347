import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, provideAppInitializer } from '@angular/core';
import { kameleoonInitializerFn } from './kameleoon.initializer';

export interface KameleoonConfig {
    siteCode: string,
}

export const KAMELEOON_CONFIG = new InjectionToken<KameleoonConfig>('KAMELEOON_CONFIG');

export function provideKameleoon(config: KameleoonConfig): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: KAMELEOON_CONFIG, useValue: config},
        provideAppInitializer(kameleoonInitializerFn),
    ]);
}
