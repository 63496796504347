import { ISbResult } from 'storyblok-js-client';
import { StoryblokRichTextOptions } from '@storyblok/richtext';

export interface ISbDataSourceEntry {
    id: number;
    name: string;
    value: string;
    dimension_value: number | null;
}

export interface ISbDataSourceEntries extends ISbResult {
    datasource_entries: ISbDataSourceEntry[];
}

export function replaceSpanWithCmp(text: string, regex = /&lt;span class=&quot;(.*?)&quot;&gt;(.*?)&lt;\/span&gt;/g): string {
    if (regex.test(text)) {
        return text.replace(regex, (match, className, content) => {
            return `<span class="${className}">${content}</span>`;
        });
    }
    return text;
}

export const SB_RICH_TEXT_OPTIONS: StoryblokRichTextOptions<string> = {
    renderFn: (tag: string, attrs: Record<string, any>, text?: string | string[]) => {
        if (Array.isArray(text)) {
            text = text.map(t => {
                return replaceSpanWithCmp(t);
            });
        } else if (text) {
            text =  replaceSpanWithCmp(text);
        }
        return `<${tag} ${Object.keys(attrs).map(key => `${key}="${attrs[key]}"`).join(' ')}>
                  ${text}
                </${tag}>`
    },
    optimizeImages: {
        class: 'app-rich-text-image',
        width: 800,
        height: 600,
        loading: 'lazy',
    },
};
