import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITariffTableColumnBlock } from './tariff-table-column.model';

@Component({
    selector: 'div[app-tariff-table-column]',
    imports: [],
    templateUrl: './tariff-table-column.component.html',
    styleUrl: './tariff-table-column.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'app-tariff-table--column',
    }
})
export class TariffTableColumnComponent {
    block = input.required<ITariffTableColumnBlock>();
}
