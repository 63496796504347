import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, provideAppInitializer } from '@angular/core';
import { usercentricsInitializerFn } from './usercentrics.initializer';

export interface UsercentricsConfig {
    id: string,
    url: string,
    targetUrl: string,
    excludedRoutes: string[],
}

export const USERCENTRICS_CONFIG = new InjectionToken<UsercentricsConfig>('USERCENTRICS_CONFIG');

export function provideUsercentrics(config: UsercentricsConfig): EnvironmentProviders {
    return makeEnvironmentProviders([
        {provide: USERCENTRICS_CONFIG, useValue: config},
        provideAppInitializer(usercentricsInitializerFn),
    ]);
}
