@let blok = block();

<p class="hidden lg:flex items-center text-lg font-bold p-4">
    {{blok.title}}
</p>
@for (column of blok.columns; track column._uid) {
    <div app-tariff-table-footer-column
         [block]="column" class="hidden lg:block"
         [columns]="columns()">
    </div>
}

@if (blok.mobileAction?.length) {
    <div class="lg:hidden">
        <app-button [block]="blok.mobileAction![0]"
                    class="w-full">
        </app-button>
    </div>
}
