import { EnvironmentProviders, InjectionToken, makeEnvironmentProviders, ɵImageConfig as ImageConfig } from '@angular/core';
import { IMAGE_CONFIG, IMAGE_LOADER } from '@angular/common';
import { storyblokImageLoaderFactory } from './loader';

export interface StoryblokImageConfig {
    dpiFactor: 1 | 2 | 3;
}

export const DEFAULT_STORYBLOK_IMAGE_CONFIG: StoryblokImageConfig = {
    dpiFactor: 1,
}

export const DEFAULT_IMAGE_CONFIG: ImageConfig = {
    breakpoints:  [256, 384, 640, 768, 828, 1024, 1200, 1920, 2048, 3840],
    placeholderResolution: 500,
    disableImageSizeWarning: false,
    disableImageLazyLoadWarning: false,
};

export const STORYBLOK_IMAGE_CONFIG = new InjectionToken<StoryblokImageConfig>('Storyblok image config', {
    providedIn: 'root',
    factory: () => DEFAULT_STORYBLOK_IMAGE_CONFIG,
})

export function provideStoryblokImages(config: StoryblokImageConfig = DEFAULT_STORYBLOK_IMAGE_CONFIG, optimizedImageConfig: ImageConfig = DEFAULT_IMAGE_CONFIG): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: STORYBLOK_IMAGE_CONFIG,
            useValue: config
        },
        {
            provide: IMAGE_CONFIG,
            useValue: optimizedImageConfig,
        },
        {
            provide: IMAGE_LOADER,
            useValue: storyblokImageLoaderFactory(config, optimizedImageConfig),
            deps: [STORYBLOK_IMAGE_CONFIG]
        },
    ]);
}
