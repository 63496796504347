import { ChangeDetectionStrategy, Component, effect, inject, input, ViewEncapsulation } from '@angular/core';

import { S1Layout } from '@seven1/model';
import { IPageBlock } from './page.model';
import { CmsComponent } from '@seven1/angular-storyblok/components';
import { LayoutWrapper } from '../layout';
import { SeoService } from '@seven1/angular/seo';

@Component({
    selector: 'app-page',
    imports: [CmsComponent, LayoutWrapper],
    templateUrl: './page.wrapper.html',
    styleUrl: './page.wrapper.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageWrapper {
    private _seo = inject(SeoService);
    block = input.required<IPageBlock>();
    layout = input.required<S1Layout>();

    constructor() {
        effect(() => {
            const blok = this.block();
            this._seo.addPageMeta(blok);
        });
    }
}
