import { environment } from './environment';
import { LayoutWrapper, PageWrapper } from '../app/cms/components/wrapper';
import {
    AdvantagesCardComponent,
    BadgeComponent,
    ButtonComponent,
    DecoratedHeadlineComponent,
    DecoratedSectionComponent,
    FaqComponent,
    FaqItemComponent,
    FooterComponent,
    FooterRecruitComponent,
    GridFiveColumnComponent,
    GridFourColumnComponent,
    GridOneColumnComponent,
    GridThreeColumnComponent,
    GridTwoColumnComponent,
    GroupComponent,
    HeadlineComponent,
    IconComponent,
    IframeComponent,
    ImageComponent,
    LinkCardComponent,
    LinkComponent,
    ListComponent,
    ListElementComponent,
    OneDirectionComponent,
    ParagraphComponent,
    PhoneNumberComponent,
    RichTextComponent,
    SectionComponent,
    TariffCardComponent,
    TariffOptionCardComponent,
    TariffOptionCardsComponent,
    TariffTableColumnComponent,
    TariffTableComponent,
    TariffTableFooterColumnComponent,
    TariffTableFooterRowComponent,
    TariffTableRowComponent,
    TariffTableRowGroupComponent,
    VideoComponent,
    TooltipComponent
} from '../app/cms/components/nested';
import { CmsComponents } from '@seven1/angular-storyblok/api';

export const STORYBLOK_COMPONENTS: CmsComponents = {
    // wrapper
    layout: LayoutWrapper,
    page: PageWrapper,
    footer: FooterComponent,
    footerRecruit: FooterRecruitComponent,
    // cards
    advantagesCard: AdvantagesCardComponent,
    linkCard: LinkCardComponent,
    tariffCard: TariffCardComponent,
    // nested
    // layout
    section: SectionComponent,
    decorated_section: DecoratedSectionComponent,
    grid_one_column: GridOneColumnComponent,
    grid_two_column: GridTwoColumnComponent,
    grid_three_column: GridThreeColumnComponent,
    grid_four_column: GridFourColumnComponent,
    grid_five_column: GridFiveColumnComponent,
    one_direction_container: OneDirectionComponent,
    group: GroupComponent,
    // text
    heading: HeadlineComponent,
    decoratedHeading: DecoratedHeadlineComponent,
    paragraph: ParagraphComponent,
    richtext: RichTextComponent,
    list: ListComponent,
    listElement: ListElementComponent,
    // media
    image: ImageComponent,
    video: VideoComponent,
    icon: IconComponent,
    // cta
    link: LinkComponent,
    button: ButtonComponent,
    phoneNumber: PhoneNumberComponent,
    badge: BadgeComponent,
    // advanced
    iframe: IframeComponent,
    faq: FaqComponent,
    faq_item: FaqItemComponent,
    tooltip: TooltipComponent,
    // tariff
    // card
    tariff_option_cards: TariffOptionCardsComponent,
    tariff_option_card: TariffOptionCardComponent,
    // table
    tariff_table: TariffTableComponent,
    tariff_table_column: TariffTableColumnComponent,
    tariff_table_row_group: TariffTableRowGroupComponent,
    tariff_table_row: TariffTableRowComponent,
    tariff_table_footer_row: TariffTableFooterRowComponent,
    tariff_table_footer_column: TariffTableFooterColumnComponent,
}
