import { inject, Injectable, isDevMode, Renderer2, RendererFactory2 } from '@angular/core';
import { PlatformService, WINDOW } from '@seven1/angular/ssr';
import { DOCUMENT } from '@angular/common';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { USERCENTRICS_CONFIG } from './usercentrics.provider';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export type BannerType = 'revocation' | 'configuration' | 'splash';
export type CmpEvent =
    | 'UC_GCM_UPDATE'
    | 'beforeunload'
    | 'cmp:backdrop-click'
    | 'cmp:clear'
    | 'cmp:cleared'
    | 'cmp:confirmed'
    | 'cmp:consents'
    | 'cmp:expand'
    | 'cmp:failed'
    | 'cmp:goToLegitimateInterest'
    | 'cmp:goToVendor'
    | 'cmp:hideBackdrop'
    | 'cmp:hideBanner'
    | 'cmp:init'
    | 'cmp:initialized'
    | 'cmp:openUrl'
    | 'cmp:revokeAll'
    | 'cmp:saved'
    | 'cmp:scroll'
    | 'cmp:setModifiers'
    | 'cmp:setVendorStatuses'
    | 'cmp:settings'
    | 'cmp:showBackdrop'
    | 'cmp:showBanner'
    | 'cmp:showStep'
    | 'cmp:step'
    | 'cmp:tooltip'
    | 'cmp:userActionNotRequired'
    | 'cmp:userActionRequired'
    | 'cmp:vendors'
    | 'haschange'
    | 'message'
    | 'mousemove'
    | 'popstate'
    | 'scroll';

export type ConsentValue = 'granted' | 'denied';
export interface ConsentData {
    adPersonalization: ConsentValue;
    adStorage: ConsentValue;
    adUserData: ConsentValue;
    analyticsStorage: ConsentValue;
    adsDataRedaction: boolean;
}
export type ConsentKey = keyof ConsentData;


export type UsercentricsWindow = Window & typeof globalThis & {
    cmp: {
        clear: () => void,
        getBundleId: () => string,
        getConsent: () => never,
        getConsentStatus: () => never,
        getConsents: () => never[],
        getControllerId: () => string,
        getSettingsId: () => string,
        getVendor: () => never,
        getVendorStatus: () => never,
        getVendors: () => never[],
        hasFailed: () => boolean,
        hideBackdrop: () => void,
        hideBanner: () => void,
        isInitialized: () => boolean,
        isUserActionRequired: () => boolean,
        refresh: () => void,
        setModifiers: (modifiers: never) => void,
        setVendorStatus: (status: never) => void,
        setVendorStatuses: (statuses: never[]) => void,
        showBackdrop: () => void,
        showBanner: (config: { step: BannerType }) => void,
        showStep: (step: never) => void,
    }
    /**
     * initiallyHidden (Boolean)
     * As the CMP banner is supposed to be hidden initially for the privacy policy page, this flag makes sure the CMP component is initialized but needs to be shown via cmp.showBanner(). This attribute is a boolean property, so leave it if it’s not needed and pass it if it is (similar to disabled or checked in HTML). initiallyHidden="false" will still be interpreted as true as the attribute itself is present.
     *
     * This attribute is supported by <cmp-banner> only (as dialogs are always visible).
     * */
}

@Injectable({
  providedIn: 'root'
})
export class UsercentricsService {
    private _config = inject(USERCENTRICS_CONFIG);
    private platformService = inject(PlatformService);
    private _document = inject(DOCUMENT);
    private _window: UsercentricsWindow = inject(WINDOW) as UsercentricsWindow;
    private _router = inject(Router);
    private _renderer: Renderer2;

    constructor(rendererFactory: RendererFactory2) {
        this._renderer = rendererFactory.createRenderer(null, null);
    }

    /** usercentrics settings */
    private get _ucSetting(): string | null {
        if (this.platformService.isBrowser) {
            return localStorage.getItem('uc_settings');
        }
        return null;
    }

    private get _ucUserInteraction(): boolean | null {
        if (this.platformService.isBrowser) {
            return localStorage.getItem('uc_user_interaction') === 'true';
        }
        return null;
    }

    private get _ucTcf(): string | null {
        if (this.platformService.isBrowser) {
            return localStorage.getItem('uc_tcf');
        }
        return null;
    }

    get ucGcm(): ConsentData | null {
        if (this.platformService.isBrowser) {
            try {
                return JSON.parse(localStorage.getItem('uc_gcm') || '');
            } catch (e) {
                if (isDevMode()) console.error(e);
            }
        }
        return null;
    }

    openBanner(type: BannerType): void {
        if (this._window?.cmp) {
            this._window.cmp?.showBanner({step: type});
        }
    }

    async hideBanner() {
        setTimeout(() => {
            this._window.cmp?.hideBanner();
        });
    }

    init() {
        this.listen('cmp:initialized', () => {
            if (this._config.excludedRoutes.includes(this._router.url)) {
                this.hideBanner();
            }
        });
        this._router.events
            .pipe(
                filter(ev => ev instanceof NavigationEnd),
                takeUntilDestroyed(),
            ).subscribe(() => {
            if (this._config.excludedRoutes.includes(this._router.url)) {
                this.hideBanner();
            } else {
                if (!this._ucUserInteraction) {
                    this.openBanner('splash');
                }
            }
        })
    }

    listen(event: CmpEvent, callback: (event: Event) => void) {
        return this._renderer.listen(this._document, event, callback);
    }

    checkConsent(key: ConsentKey | 'all' = 'all'): boolean {
        const settings = this.ucGcm;
        switch (key) {
            case "adPersonalization":
                return settings?.adPersonalization === 'granted';
            case "adUserData":
                return settings?.adUserData === 'granted';
            case "analyticsStorage":
                return settings?.analyticsStorage === 'granted';
            case "adStorage":
                return settings?.adStorage === 'granted';
            case "all":
            default:
                return settings?.adPersonalization === 'granted'
                    && settings?.adUserData === 'granted'
                    && settings?.analyticsStorage === 'granted'
                    && settings?.adStorage === 'granted';
        }
    }
}
