import { ChangeDetectionStrategy, Component, input, ViewEncapsulation } from '@angular/core';
import { ITooltipBlock } from './tooltip.model';

@Component({
    selector: 'app-tooltip',
    imports: [],
    templateUrl: './tooltip.component.html',
    styleUrl: './tooltip.component.scss',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        class: 'relative group inline-block w-10 h-10 border-2 solid flex justify-center items-center rounded-full',
    }
})
export class TooltipComponent {
    block = input.required<ITooltipBlock>();
}
