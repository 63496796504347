@let blok = block();
<div class="flex flex-col"
     [ngClass]="blok.contentAligned ? 'justify-center':''">
    @for (child of blok.column_one; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>
<div class="flex flex-col"
     [ngClass]="blok.contentAligned ? 'justify-center':''">
    @for (child of blok.column_two; track child._uid) {
        <s1-cms-component [blok]="child"/>
    }
</div>

